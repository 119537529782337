import React, { useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import InputGroup from '../components/ui/InputGroup';
import InputSelect from '../components/ui/InputSelect';
import InputSelectOption from '../components/ui/InputSelectOption';
import Label from '../components/ui/Label';
import StepperLayout from '../components/layouts/StepperLayout';
import {
  getBillPresenceType,
  getSolarSystemDetails,
  setSolarSystemDetails,
} from '../utils/localStorage/localStorageFunctions';
import { SolarDetailsLsType } from '../utils/localStorage/localStorageTypes';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const defaultTiltValue = 20;
const defaultLossesValue = 22;

const AboutYourSolarSchema = Yup.object().shape({
  solarSize: Yup.number()
    .typeError('System size must be a number')
    .min(0.01, 'System size must be more than 0')
    .max(30, 'Please check the system size')
    .required('Please, specify your system size'),
  tilt: Yup.number()
    .typeError('System tilt must be a number')
    .min(0.01, 'System tilt must be more than 0')
    .max(60, 'Please check the system tilt'),
  losses: Yup.number()
    .typeError('System losses must be a number')
    .min(0.01, 'System losses must be more than 0')
    .max(65, 'Please check the system losses'),
  eastPercent: Yup.number()
    .typeError('East facing percentage must be a number')
    .min(0.01, 'East facing percentage must be more than 0')
    .max(100, 'Please check the east facing percentage'),
  direction: Yup.string().required('Please, specify the direction'),
});

const SolarSystemDetails = () => {
  const [isExtraInputSectionExpanded, setIsExtraInputSectionExpanded] =
    useState(() => {
      const { solarLosses, solarTilt } = getSolarSystemDetails() || {};
      // if values exist
      if (!solarLosses || !solarTilt) return false;
      // if values are default
      return (
        solarLosses !== defaultLossesValue || solarTilt !== defaultTiltValue
      );
    });

  const [initialFromValues] = useState(() => {
    const {
      solarLosses,
      solarTilt,
      solarSystemSize,
      solarDirection,
      eastPercent,
    } = getSolarSystemDetails() || {};

    return {
      solarSize: solarSystemSize || 6.6,
      direction: solarDirection?.toString() || '0',
      tilt: solarTilt || defaultTiltValue,
      losses: solarLosses || defaultLossesValue,
      eastPercent: eastPercent || 50,
    };
  });

  const handleNext = (values: typeof initialFromValues) => {
    const solarSystemDetails: SolarDetailsLsType = {
      solarSystemSize: values.solarSize,
      solarDirection: parseInt(values.direction, 10),
      solarTilt: values.tilt,
      solarLosses: values.losses,
    };

    if (values.direction === '-90') {
      solarSystemDetails.eastPercent = values.eastPercent;
    }

    setSolarSystemDetails(solarSystemDetails);

    navigateAndSetActiveStep(StepUrl.URL_RETAILER_CHOICE);
  };

  const handleBack = () => {
    const billType = getBillPresenceType();
    if (billType === 'paperBill') {
      navigateAndSetActiveStep(StepUrl.URL_SOLAR_INPUT);
      return;
    }

    navigateAndSetActiveStep(StepUrl.URL_SOLAR_INSTALLED);
  };

  const toggleExtraInputSection = (
    formikProps: FormikProps<typeof initialFromValues>
  ) => {
    if (isExtraInputSectionExpanded) {
      setIsExtraInputSectionExpanded(false);
      formikProps.setFieldValue('tilt', defaultTiltValue);
      formikProps.setFieldValue('losses', defaultLossesValue);
      return;
    }
    setIsExtraInputSectionExpanded(true);
  };

  usePreloadPageData(
    StepUrl.URL_RETAILER_CHOICE,
    StepUrl.URL_SOLAR_INPUT,
    StepUrl.URL_SOLAR_INSTALLED
  );

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={2}
        currentStep={11}
        pageUrl={StepUrl.URL_SOLAR_SYSTEM_DETAILS}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFromValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
            validationSchema={AboutYourSolarSchema}
          >
            {(formikProps) => (
              <Form>
                <h2 className="my-2">About your solar system</h2>
                <InputGroup className="my-2">
                  <Label htmlFor="input-solar-size">
                    What is the size of your solar system?
                  </Label>
                  <Input
                    id="input-solar-size"
                    name="solarSize"
                    type="number"
                    unit="kWp"
                    controls
                    min={0.1}
                    max={30}
                    step={0.1}
                  />
                </InputGroup>
                <InputGroup className="mt-2 mb-0">
                  <p className="text-label" id="dir-label">
                    What direction does your solar system face?
                  </p>
                  <InputSelect
                    name="direction"
                    id="dir-select"
                    aria-labelledby="dir-label dir-select"
                  >
                    <InputSelectOption value="0">North</InputSelectOption>
                    <InputSelectOption value="45">North East</InputSelectOption>
                    <InputSelectOption value="90">East</InputSelectOption>
                    <InputSelectOption value="135">
                      South East
                    </InputSelectOption>
                    <InputSelectOption value="180">South</InputSelectOption>
                    <InputSelectOption value="225">
                      South West
                    </InputSelectOption>
                    <InputSelectOption value="270">West</InputSelectOption>
                    <InputSelectOption value="315">
                      North West
                    </InputSelectOption>
                    <InputSelectOption value="-90">
                      East & West
                    </InputSelectOption>
                  </InputSelect>
                </InputGroup>
                {/* Removed East percentage input. Using default 50% value */}
                {/* {formikProps.values.direction === '-90' && ( */}
                {/*  <InputGroup className="mt-4 mb-0"> */}
                {/*    <Label htmlFor="input-east-percent"> */}
                {/*      What proportion of solar system faces East? */}
                {/*    </Label> */}
                {/*    <Input */}
                {/*      id="input-east-percent" */}
                {/*      name="eastPercent" */}
                {/*      type="number" */}
                {/*      unit="kWp" */}
                {/*      controls */}
                {/*      min={0.01} */}
                {/*      max={100} */}
                {/*      step={0.01} */}
                {/*    /> */}
                {/*  </InputGroup> */}
                {/* )} */}
                <Button
                  className="my-4"
                  type="button"
                  variant="link"
                  onClick={() => {
                    toggleExtraInputSection(formikProps);
                  }}
                >
                  {isExtraInputSectionExpanded
                    ? 'Remove advanced details: panel tilt and losses'
                    : 'Add advanced details: panel tilt and losses'}
                </Button>
                {isExtraInputSectionExpanded && (
                  <>
                    <InputGroup className="my-2">
                      <Label htmlFor="input-solar-size">Tilt</Label>
                      <Input
                        id="input-solar-size"
                        name="tilt"
                        type="number"
                        unit="deg"
                        controls
                        min={0.01}
                        max={60}
                        step={0.01}
                      />
                    </InputGroup>
                    <InputGroup className="my-2">
                      <Label htmlFor="input-solar-size">Losses</Label>
                      <Input
                        id="input-solar-size"
                        name="losses"
                        type="number"
                        unit="%"
                        controls
                        min={0.01}
                        max={65}
                        step={0.01}
                      />
                    </InputGroup>
                  </>
                )}

                <Button
                  className="mt-5"
                  type="submit"
                  disabled={
                    !formikProps.values.solarSize ||
                    formikProps.values.direction === ''
                  }
                >
                  Continue
                </Button>
                <div className="my-3">
                  <BackButton text="Back" onClick={handleBack} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </StepperLayout>
    </SEOLayout>
  );
};

export default SolarSystemDetails;
